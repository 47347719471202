<template>
  <div>
    <v-tooltip top v-for="(status, index) in valueFiltered" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :color="lessonStatusColor(status)"
          class="ma-0 pa-0"
          x-small
          >{{ lessonStatusIcon(status) }}</v-icon
        >
      </template>
      <span>{{ status.description }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { lessonStatusColor, lessonStatusIcon } from "common/utils/icons.js";
export default {
  props: {
    value: [],
  },
  computed: {
    valueFiltered() {
      return this.value.filter((el) => el.code != "asPlanned");
    },
  },
  methods: {
    lessonStatusColor,
    lessonStatusIcon,
  },
};
</script>
